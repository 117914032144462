function ReactPage() {
    return (
        <div className="reactpage container-fluid">
            <div className="row img-background fixed-height">
                <div className="fixed-width">
                    <div className="row pt-4">
                        <div className="col-md-6">
                            <h2>React</h2>
                            <p>I utilise the React platform for developing website front ends due to it's large adoption, this has led to huge support network for any potential troubleshooting.</p>
                            <p>The platform is also easy to use for starting new projects with only a few commands.</p>
                        </div>
                        <div className="col-md-6">
                            <pre>
                                <code>
                                    <p>$ npm install -g create-react-app</p>
                                    <p>$ npm init react-app sample --use-npm</p>
                                    <p>$ cd sample</p>
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactPage;