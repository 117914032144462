import { Outlet, NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import React from "react";

// https://www.youtube.com/watch?v=5s57C7leXc4

export default function RootLayout() {
  return (
      <div className="root-layout">
          <Navbar bg="light" expand="lg" >
              <Container>
                  <Navbar.Brand href="/">Adam Payne</Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                          {/*<NavLink to="/" className="nav-link"><i className="fa-solid fa-house"/></NavLink>*/}
                          <NavLink to="containers" className="nav-link">Containers</NavLink>
                          <NavLink to="reactpage" className="nav-link">React</NavLink>
                          <Nav.Link href="https://www.linkedin.com/in/adampayne0/">LinkedIn</Nav.Link>
                      </Nav>
                  </Navbar.Collapse>
              </Container>
          </Navbar>
        <main>
            <Outlet />
        </main>
      </div>
  )
}
