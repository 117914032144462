import adam from '../images/adam.jpg';
import logos from '../images/homepagelogos.png';
import azure from '../images/azure.png';
import aws from '../images/aws.png';
import reactlogo from '../images/react.png';
import keyboard from '../images/keyboard.png';

function Home() {
  return (
      <div className="home container-fluid">
          <div className="row">
              <div className="col-md-6">
                  <img src={adam} alt="Adam Payne" className="width100" />
              </div>
              <div className="col-md-6">
                  <div className="row">
                      <div className="col-xs-12 pt-5">
                          <h1>Adam Payne</h1>
                      </div>
                      <div className="col-xs-12 pt-5">
                          <h2>Advanced software engineer <span className="orangefont">working remotely.</span></h2>
                      </div>
                      <div className="col-xs-12 pt-5">
                          <a className="btn btn-primary" href="mailto:adam@payne.rocks" target="_blank" rel="noopener noreferrer"><i className="fa-regular fa-envelope p-2"></i>Email me</a>
                      </div>
                      <div className="col-xs-12 pt-5">
                          <img src={logos} alt="kubernetes react csharp docker" className="" />
                      </div>
                  </div>
              </div>
          </div>
          <div className="row img-background fixed-height">
              <div className="fixed-width">
                  <div className="row pt-4">
                      <div className="col-md-6">
                          <h2>Strong skillset</h2>
                          <h3>Cloud first development, critical thinking, pragmatic approach</h3>
                      </div>
                      <div className="col-md-6">
                          <ul>
                              <li>Kubernetes (K8s)</li>
                              <li>Azure</li>
                              <li>Amazon Web Services (AWS)</li>
                              <li>Service Orientated Architecture (SOA)</li>
                              <li>Containers and functions</li>
                              <li>Event based messaging</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
          <div className="row img-background fixed-height">
              <div className="fixed-width">
                  <div className="row pt-4">
                      <div className="col-md-6">
                          <p>
                              Cloud technologies are the future and I enjoy realising modern architectures. I utilise a mixture of Amazon Web Services and Microsoft Azure to host my .NET Core web based applications.
</p>
                          <p>
                              In order to get rich capabilities from my architectures, I continue to try and use the most relevant defacto open source technologies such as Kubernetes.
</p>
                          <p>
                              Delivering future state, by utilising event based microservice architectures and realising applications using containers and a service based architecture is extremely enjoyable.
                          </p>
                      </div>
                      <div className="col-md-6 text-center">
                          <img src={azure} alt="kubernetes react csharp docker" className="fixed-width-sm p-3" />
                          <img src={aws} alt="kubernetes react csharp docker" className="fixed-width-sm p-3" />
                      </div>
                  </div>
              </div>
          </div>
          <div className="row img-background fixed-height">
              <div className="fixed-width">
                  <div className="row pt-4">
                      <div className="col-md-6">
                          <p>React front ends allow me to have feature rich websites on top of my APIs with access to a large online user support base.</p>
                          <p>Championing best practice web based development and ensuring coding standards are met in my team allows maintenance to be kept under control.</p>
                      </div>
                      <div className="col-md-6 text-center">
                          <img src={reactlogo} alt="kubernetes react csharp docker" className="fixed-width-sm p-3" />
                          <img src={keyboard} alt="kubernetes react csharp docker" className="fixed-width-sm p-3" />
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default Home;
