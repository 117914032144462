import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import React from "react";

// Routing
import {createRoutesFromElements, Route, RouterProvider} from "react-router";
import {createBrowserRouter} from "react-router-dom";

// layouts
import RootLayout from './layouts/RootLayout'

// Pages
import Home from "./pages/Home";
import Containers from "./pages/Containers";
import ReactPage from "./pages/React.js";

let router;
router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout/>}>
          <Route index element={<Home/>}/>
            <Route path="containers" element={<Containers/>}/>
            <Route path="reactpage" element={<ReactPage/>}/>
        </Route>
    )
);
function App() {
  return (

      <div className="App">
        <RouterProvider router={router} />
      </div>

  );
}

export default App;
