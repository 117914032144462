function Containers() {
    return (
        <div className="containers container-fluid">
            <div className="row img-background fixed-height">
                <div className="fixed-width">
                    <div className="row pt-4">
                        <div className="col-md-6">
                            <h2>Containers (Docker)</h2>
                            <p>Containers are a fantastic solution to software that may need to switch between hosting providers.</p>
                            <p>As the name suggests containing the software within defined parameters.</p>
                            <p>Docker is the recommended de-facto standard.</p>
                        </div>
                        <div className="col-md-6">
                            <pre>
                                <code>
                                    <p>FROM mcr.microsoft.com/dotnet/sdk:7.0 AS build-env</p>
                                    <p>WORKDIR /App</p>
                                    <p>COPY . ./</p>
                                    <p>RUN dotnet restore</p>
                                    <p>RUN dotnet publish -c Release -o out</p>
                                    <p>FROM mcr.microsoft.com/dotnet/aspnet:7.0</p>
                                    <p>WORKDIR /App</p>
                                    <p>COPY --from=build-env /App/out .</p>
                                    <p>ENTRYPOINT ["dotnet", "DotNet.Docker.dll"]</p>
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row img-background fixed-height">
                <div className="fixed-width">
                    <div className="row pt-4">
                        <div className="col-md-6">
                            <h2>Orchestration (Kubernetes)</h2>
                            <p>Orchestration of containers on K8s is the standard approach.</p>
                            <p>I recommend using K8s to manage distributed systems as all of the major hosting providers support it.</p>
                            <p>K8s can manage scaling requirements, availability, failover, deployment patterns, and more.</p>
                        </div>
                        <div className="col-md-6">
                            <h2>Cluster management (Rancher)</h2>
                            <p>Getting started with Kubernetes on your desktop can be difficult.</p>
                            <p>Especially if you want to match the version of Kubernetes you run locally to the one you run in production.</p>
                            <p>Rancher Desktop makes it easier to manage and administrate your K8s clusters.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Containers;